<template>
  <quill-editor
      v-model="content"
      :options="editorOption"
      @input="emitContent"
  >
  </quill-editor>

</template>

<script>
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { codeCustom } from './code'

export default {
  components: {
    quillEditor,
    BCardCode,
    BCardText,
  },
  props: {
    itemEdicao: String,
    toolbarId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Informe a descrição',
    },
  },
  data() {
    return {
      codeCustom,
      content: '',
      editorOption: {
        modules: {},
      },

    }
  },
  created() {
    this.editorOption.placeholder = this.placeholder
    if (this.itemEdicao) {      
      this.content = this.itemEdicao
    }
  },
  watch: {
    itemEdicao: {    
      handler: function (val) {          
        this.content = val
      },
      deep: true,
    },
  },
  methods: {
    emitContent(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

</style>